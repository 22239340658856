import React from "react";
import ScrollToTop from "../../components/ScrollToTop";

const CookiePolicy = () => {
  return (
    <>
      <section className="policy pt-6">
        <div className="container pt-4">
          <div className="policyTextContent">
            <div className="policyContentHeader">
              <h1>Cookie Policy</h1>
              <small>Last Updated On 29-Dec-2022</small>{" "}
              <small>Effective Date 28-Dec-2022</small>
            </div>
            <div className="policyContent">
              <article>
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Commodi rem optio nam consequatur. Fugiat exercitationem ad
                  accusamus impedit, perferendis molestiae consectetur
                  distinctio vitae doloremque dolores officia eveniet dolorem
                  optio! Placeat, ea? Ullam nostrum, ipsa aspernatur
                  perspiciatis veritatis cum excepturi accusantium delectus
                  laudantium commodi harum fugit voluptate odit ipsum soluta
                  corporis pariatur. Neque eius optio enim possimus id illo
                  perspiciatis nisi aliquid, officia dolore recusandae
                  reprehenderit iusto nostrum ex quam molestiae, assumenda
                  libero veritatis omnis sed. Vitae exercitationem saepe sunt.
                  Minus, omnis corrupti. Quam minus veritatis optio ea rem quos
                  molestiae nihil temporibus nisi saepe voluptatibus ad culpa,
                  provident laudantium quaerat.
                </p>
                <h4>Lorem, ipsum dolor:</h4>
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Illum vitae error quam voluptatibus nisi amet vero, temporibus
                  deleniti sunt eius?
                  <li>lorem</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                </p>
                <h4>Lorem ipsum dolor sit amet.</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Et,
                  esse.
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                  <li>Lorem ipsum</li>
                </p>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Nobis ipsam similique ex quos deserunt odio porro numquam
                  doloribus excepturi quo. Ea obcaecati totam quas natus beatae,
                  eveniet at ipsam ad.
                </p>
                <h4> Lorem ipsum dolor sit.</h4>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Quisquam nihil perferendis nostrum veniam debitis cum,
                  expedita laborum porro illum placeat totam nisi recusandae
                  fugit officia cumque ducimus saepe repellat facere fugiat
                  voluptate numquam ratione natus harum dicta. Eaque eligendi
                  consectetur quis nesciunt aliquam libero velit magnam laborum
                  facilis veritatis error doloribus quae tempore, iusto maxime
                  incidunt aliquid saepe soluta assumenda?
                </p>
                <h4>Lorem, ipsum.</h4>
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Rerum illo fugit quibusdam eligendi magnam architecto itaque
                  veniam delectus, nihil optio dolore distinctio, nostrum
                  doloremque ea! Voluptatem deserunt neque alias qui fugiat
                  asperiores vitae beatae, doloremque tenetur voluptates totam
                  dolorum nihil consectetur magnam quia repellendus modi
                  voluptatum incidunt cum! Dignissimos, autem.
                  <br />
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum
                  et labore optio neque laborum qui modi vitae pariatur velit
                  accusamus?
                  <br />
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                  nisi neque sed laboriosam, corporis est ratione deleniti.
                  Autem aperiam quas deleniti molestiae at aliquid quisquam
                  error! Earum, nisi? Qui eius quibusdam inventore corrupti
                  ducimus! Dolore fugiat quod perspiciatis repudiandae eos!
                </p>
                <h4>Lorem, ipsum.</h4>{" "}
                <p>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iste
                  laborum rem magni repudiandae natus veritatis perspiciatis,
                  earum dolore illum ad.
                </p>
                <h4>Lorem ipsum</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Beatae placeat dolorum est, soluta rem sit possimus sequi
                  minima blanditiis dolores quibusdam qui quasi atque ipsa ut
                  expedita, pariatur sapiente minus perferendis aut iure
                  architecto corporis labore eos! Saepe deserunt laudantium,
                  iste hic sequi dolor dolorem rem, illo fuga odit cumque.
                </p>
                <h4>Lorem, ipsum dolor.</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
                  natus consectetur eligendi autem porro? Enim, adipisci, ullam
                  eaque corporis molestias iusto illo asperiores, earum nesciunt
                  odit magni maxime consequatur praesentium!
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CookiePolicy;
