import { Outlet } from "react-router-dom";

import { Countdown, CTAButton, Footer, Navbar } from "../components";

import FloatButtons from "../ui/floatButtons";

function Layout() {
  return (

    <div className="layout-container">
    {/* <Countdown /> */}
    <div className="main-content">

      <Navbar />
      <Outlet />
      <Footer />
      {/* <CTAButton
        text="BIPOC Professional Form"
        link="https://form.jotform.com/232890162361050"
      /> */}
      <FloatButtons />

      </div>

    </div>
  );
}

export default Layout;
