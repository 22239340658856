import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../styles.css";
import "./donate.css";
import { stickerImg, supportImg } from "../../assets";
import { donateContent } from "../../constants/data";
import {  DonatePayment, ScrollToTop } from "../../components";
import StripePayment from "../../components/StripePayment";
import { Link } from "react-router-dom";
import DonatePageSkeleton from "../../components/skeletons/DonatePageSkeleton";
import DonateMetadata from "../../components/generateMetadata/DonateMetadata";

const DonatePage = () => {
  const [activeTab, setActiveTab] = useState("regular");
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    if (tab === "stripe" || tab === "regular") {
      setActiveTab(tab);
    }

    // Simulate data loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [location]);

  if (loading) {
    return <DonatePageSkeleton />;
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case "regular":
        return (
          <>
            <DonateMetadata />
            {/* Support a cause section */}
            <section className="supportSection pt-2">
              <div className="container pt-4">
                <div className="row justify-content-center">
                  <div className="col-12 ">
                    <div className="d-md-flex align-items-end pb-3 d-none">
                      <h1 className="supportText">Support</h1>&nbsp;&nbsp;&nbsp;
                      <img
                        loading="lazy"
                        src={supportImg}
                        className="img-fluid supportImg"
                      />
                      <h1 className="supportText">a cause</h1>
                    </div>
                    <div className="d-block d-md-none">
                      <h1 className="supportText text-center">
                        Support a cause
                      </h1>
                    </div>
                    <div className="px-lg-5 supportMargin">
                      <p>{donateContent.supportText}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* payment methods section */}
            <section className="paymentMethodList">
              <div className="container ">
                <div className="row">
                  <div className="col-12">
                    <div className="px-lg-5 supportMargin">
                      <h2 style={{ marginBottom: "25px" }}>
                        M.O.M accepts the following
                      </h2>

                      <ul className="methodList">
                        {donateContent.paymentMethodList.map(
                          (method, index) => (
                            <li key={"payM#" + index}>
                              <span className="ml-3 ml-lg-0">{method}</span>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "2rem" }}>
                  <DonatePayment />
                </div>
              </div>
            </section>

            {/* Payment process section */}
            <section className="paymentProcess my-3">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center justify-content-center">
                    <h2 className="mb-4">Other Payment Methods</h2>
                  </div>
                </div>

                {/* methods */}
                <div className="row mt-5 pt-lg-3 pt-5 card-group">
                  {/* bank card */}
                  <div className="col-lg-6 col-12">
                    <div className="card h-100 bank position-relative">
                      <img
                        loading="lazy"
                        src={donateContent.methods[0].icon}
                        className="donateIcon"
                      />

                      {/* header */}
                      <div className="card-header text-center">
                        <h2 className="card-title pt-5">
                          {donateContent.methods[0].title}
                        </h2>
                        <p className="card-text px-lg-3">
                          {donateContent.methods[0].description}
                        </p>
                      </div>

                      {/* body */}
                      <div className="card-body pb-2">
                        <ul>
                          {donateContent.methods[0].steps.map((step, index) => (
                            <li key={"step#" + index}>
                              <span className="ml-3">{step}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* cheque card */}
                  <div className="col-lg-6 col-12">
                    <div className="card h-100 bank position-relative">
                      <img
                        loading="lazy"
                        src={donateContent.methods[1].icon}
                        className="donateIcon"
                      />

                      {/* header */}
                      <div className="card-header text-center">
                        <h2 className="card-title pt-5 cheque">
                          {donateContent.methods[1].title}
                        </h2>
                        <p className="card-text px-lg-3">
                          {donateContent.methods[1].description}
                        </p>
                      </div>

                      {/* body */}
                      <div className="card-body pb-2">
                        <ul>
                          {donateContent.methods[1].steps.map((step, index) => (
                            <li key={"step#" + index}>
                              <span className="ml-3">{step}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* email money card */}
                  <div className="col-lg-6 col-12">
                    <div className="card h-100 bank position-relative">
                      <img
                        loading="lazy"
                        src={donateContent.methods[2].icon}
                        className="donateIcon"
                      />

                      {/* header */}
                      <div className="card-header text-center">
                        <h2 className="card-title pt-5 emailMoney">
                          {donateContent.methods[2].title}{" "}
                          <span>donate@meshofmother.ca</span>
                        </h2>
                        <p className="card-text px-lg-3">
                          {donateContent.methods[2].description}
                        </p>
                      </div>

                      {/* body */}
                      <div className="card-body pb-2">
                        <ul>
                          {donateContent.methods[2].steps.map((step, index) => (
                            <li key={"step#" + index}>
                              <span className="ml-3">{step}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* other payment methods */}
                  <div className="col-lg-6 col-12">
                    <div className="card h-100 bank position-relative">
                      <img
                        loading="lazy"
                        src={donateContent.methods[3].icon}
                        className="donateIcon"
                      />

                      {/* header */}
                      <div className="card-header text-center">
                        <h2 className="card-title pt-5 other">
                          {donateContent.methods[3].title}
                        </h2>
                        <p className="card-text px-lg-2">
                          {donateContent.methods[3].description}
                        </p>
                      </div>

                      {/* body */}
                      <div className="card-body pb-2">
                        <ul>
                          {donateContent.methods[3].steps.map((step, index) => (
                            <li key={"step#" + index}>
                              <span className="ml-3">{step}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* sticker section */}
            <section className="stickerSection">
              <div className="container py-5 mb-4">
                <div className="row w-75">
                  <div className="col-12">
                    <img
                      loading="lazy"
                      src={stickerImg}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
          </>
        );
      case "stripe":
        return <StripePayment />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="container pt-6">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "regular" ? "active" : ""}`}
              onClick={() => setActiveTab("regular")}
            >
              How To Donate
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "stripe" ? "active" : ""}`}
              onClick={() => setActiveTab("stripe")}
            >
              Manual Payment
            </button>
          </li>
        </ul>
      </div>

      {renderTabContent()}

  
      <ScrollToTop />
    </>
  );
};

export default DonatePage;
