import React, { useEffect, useState } from "react";
import { LogoColoured, girlsGroupImg, welcomeMom } from "../../assets";
import { aboutContent } from "../../constants/data";
import "../../styles.css";
import "./about-us.css";
import { placeholderImage } from "../../utils/placeholderImage";
import {  ScrollToTop } from "../../components";
import AboutUsSkeleton from "../../components/skeletons/AboutUsSkeleton";
import AboutUsMetadata from "../../components/generateMetadata/AboutUsMetadata";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    console.log("image: ", welcomeMom);
  }, []);

  if (loading) {
    return <AboutUsSkeleton />;
  }

  return (
    <>
      <AboutUsMetadata />
      {/* Hello */}
      <section className="helloSection pt-6">
        <div className="container pt-5">
          <div className="row">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <img
                loading="lazy"
                src={welcomeMom}
                className={`img-fluid}`}
                alt="girst group image"
              />
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-1">
              <h1 className="helloText">Hello!</h1>

              <p>{aboutContent.helloContent}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p>{aboutContent.helloContent2}</p>
            </div>
          </div>
        </div>
      </section>
      {/* Our Logo */}
      <section className="ourLogo">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h1 className="">Our Logo</h1>

              <p>{aboutContent.ourLogo}</p>
            </div>
            <div className="col-12 col-lg-6">
              <img
                loading="lazy"
                src={LogoColoured}
                className="img-fluid"
                alt="Logo colored"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Attributes */}
      <section className="attributes">
        <div className="container">
          {/* Mission */}
          <div className="row">
            <div className="col-lg-10 col-12 mission p-2">
              <div className="row p-4">
                <div className="col-12 col-lg-6 position-relative">
                  <img
                    loading="lazy"
                    src={aboutContent.missionImg}
                    className="img-fluid attributeImg d-none d-lg-block"
                    alt=""
                  />
                </div>
                <div className="col-12 col-lg-6 pl-lg-0">
                  <h2>Mission</h2>
                  <p className="mb-0 missionStmt">{aboutContent.mission}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>

          {/* Vision */}
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-10 col-12 vision p-4">
              <div className="row p-4">
                <div className="col-12 col-lg-3"></div>
                <div className="col-12 col-lg-5 pl-0 pl-lg-4">
                  <h2>Vision</h2>
                  <p className="mb-0 text-uppercase">{aboutContent.vision}</p>
                </div>
                <div className="col-12 col-lg-4 position-relative">
                  <img
                    loading="lazy"
                    src={aboutContent.visionImg}
                    className="img-fluid attributeImg2 d-none d-lg-block"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Values */}
          <div className="row">
            <div className="col-lg-10 col-12 values p-3">
              <div className="row p-2">
                <div className="col-12 col-lg-6 position-relative">
                  <img
                    loading="lazy"
                    src={aboutContent.valuesImg}
                    className="img-fluid attributeImg3 d-none d-lg-block"
                    alt=""
                  />
                </div>
                <div className="col-12 col-lg-6 pl-4 pl-lg-0">
                  <h2 className="mb-2">Values</h2>
                  <div id="con">
                    {aboutContent.values.map((value, index) => (
                      <div className="" key={index}>
                        <p className="my-0">{value}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Girls group */}
      <section className="girlGroup">
        <div className="container">
          <div className="imgWrapper">
            <img loading="lazy" src={girlsGroupImg} alt="girl group" />
          </div>
        </div>
      </section>
      {/* Architects of success */}
      <section className="architects pt-4" id="architects">
        <div className="container">
          {/* heading */}
          <div className="row">
            <div className="col-12 py-5">
              <h1 className="text-center">
                {aboutContent.architectsSection.title}
              </h1>
              <h3 className="text-center">
                {aboutContent.architectsSection.subtitle}
              </h3>
            </div>
          </div>

          {/* members */}
          <div className="row members">
            <div className="col-12 px-5">
              {aboutContent.architectsSection.members.map((member, index) => (
                <div
                  key={"member#" + index}
                  className="row px-0 px-lg-5 mb-5 member"
                >
                  <div className="col-12">
                    <div
                      className={`row align-items-end px-lg-6 ${
                        index === 1 && "mr-6"
                      }`}
                    >
                      <div
                        className={`col-lg-4 col-md-6 col-12  ${
                          index % 2 !== 0 && "order-2"
                        }`}
                      >
                        <div className="memberImg">
                          <img
                            loading="lazy"
                            src={member.image}
                            className={`img-fluid millicent2`}
                            alt={member.name}
                          />
                        </div>
                      </div>
                      <div
                        className={`col-lg-8 col-md-6 col-12 ${
                          index % 2 !== 0 && "order-2 rightContent order-lg-1"
                        } ${index === 1 && "mb-5"}`}
                      >
                        <h2 className="pt-lg-0 pt-3">{member.name}</h2>
                        <h4>{member.title}</h4>
                        <p className="mt-4 mb-2">{member.paragraph1}</p>
                      </div>
                    </div>

                    <p className="mt-5">{member.paragraph2}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
       <ScrollToTop />
    </>
  );
};

export default AboutUs;
