import
{
  buildingTogetherImg,
  getEmpoweredImg,
  joinUsImg,
  mediaMembershipImg,
  supportImg,
  member1,
  member2,
  member3,
  missionImg,
  visionImg,
  valuesImg,
  volunteerImg,
  famImg,
  ceoImg,
  payMethodIcon1,
  payMethodIcon2,
  payMethodIcon3,
  payMethodIcon4,
  motheringUpImg,
  strongerTogetherImg,
  fitYourLifeImg,
  mindfulnessServicesImg,
  TerraImg,
  upcomingEventImg1,
  upcomingEventImg2,
  upcomingEventImg3,
  upcomingEventImg4,
  VelteLogo,
  msImg,
  acImg,
  csfImg,
  efbImg,
  hcImg,
  ilImg,
} from "../assets";

import img1 from "../assets/images/events/mom-meet-2022/DSC_2155.JPG";
import img2 from "../assets/images/events/mom-meet-2022/DSC_2155.JPG";
import img3 from "../assets/images/events/mom-meet-2022/DSC_2155.JPG";
import img4 from "../assets/images/events/mom-meet-2022/DSC_2155.JPG";
import img5 from "../assets/images/events/mom-meet-2022/DSC_2155.JPG";
import img6 from "../assets/images/events/mom-meet-2022/DSC_2155.JPG";

import mus1 from "../assets/images/events/mustard-seed/1.jpg";
import mus2 from "../assets/images/events/mustard-seed/2.jpg";
import mus3 from "../assets/images/events/mustard-seed/3.jpg";
import mus4 from "../assets/images/events/mustard-seed/4.jpg";
import mus5 from "../assets/images/events/mustard-seed/5.jpg";
import mus6 from "../assets/images/events/mustard-seed/6.jpg";
import mus7 from "../assets/images/events/mustard-seed/7.jpg";
import mus8 from "../assets/images/events/mustard-seed/8.jpg";
import mus9 from "../assets/images/events/mustard-seed/9.JPG";
import mus10 from "../assets/images/events/mustard-seed/10.JPG";
import mus11 from "../assets/images/events/mustard-seed/11.JPG";
import mus12 from "../assets/images/events/mustard-seed/12.JPG";
import mus13 from "../assets/images/events/mustard-seed/13.JPG";
import mus14 from "../assets/images/events/mustard-seed/14.JPG";

import meet2 from "../assets/images/events/mom-meet-2022/DSC_1882.JPG";
import meet3 from "../assets/images/events/mom-meet-2022/DSC_1883.JPG";
import meet4 from "../assets/images/events/mom-meet-2022/DSC_1893.JPG";
import meet5 from "../assets/images/events/mom-meet-2022/DSC_1897.JPG";
import meet6 from "../assets/images/events/mom-meet-2022/DSC_1908.JPG";
import meet7 from "../assets/images/events/mom-meet-2022/DSC_1909.JPG";
import meet8 from "../assets/images/events/mom-meet-2022/DSC_1918.JPG";
import meet9 from "../assets/images/events/mom-meet-2022/DSC_1933.JPG";
import meet10 from "../assets/images/events/mom-meet-2022/DSC_1935.JPG";
import meet11 from "../assets/images/events/mom-meet-2022/DSC_1943.JPG";
import meet12 from "../assets/images/events/mom-meet-2022/DSC_1944.JPG";
import meet13 from "../assets/images/events/mom-meet-2022/DSC_2255.JPG";
import meet14 from "../assets/images/events/mom-meet-2022/DSC_2287.JPG";
import meet15 from "../assets/images/events/mom-meet-2022/DSC_2145.JPG";
import meet16 from "../assets/images/events/mom-meet-2022/DSC_2208.JPG";
import meet17 from "../assets/images/events/mom-meet-2022/DSC_2227.JPG";
import meet18 from "../assets/images/events/mom-meet-2022/DSC_2289.JPG";
import meet19 from "../assets/images/events/mom-meet-2022/DSC_2356.JPG";
import meet20 from "../assets/images/events/mom-meet-2022/DSC_2169.JPG";
import meet21 from "../assets/images/events/mom-meet-2022/DSC_2096.JPG";

import ha1 from "../assets/images/events/harry-ainley/1.jpg";
import ha2 from "../assets/images/events/harry-ainley/2.jpg";
import ha3 from "../assets/images/events/harry-ainley/3.jpg";
import ha4 from "../assets/images/events/harry-ainley/4.jpg";
import ha5 from "../assets/images/events/harry-ainley/5.jpg";
import ha6 from "../assets/images/events/harry-ainley/6.jpg";

import ft1 from "../assets/images/events/fitness-2022/1.jpg";
import ft2 from "../assets/images/events/fitness-2022/2.jpg";
import ft3 from "../assets/images/events/fitness-2022/3.jpg";
import ft4 from "../assets/images/events/fitness-2022/4.jpg";
import ft5 from "../assets/images/events/fitness-2022/5.jpg";
import ft6 from "../assets/images/events/fitness-2022/6.jpg";
import ft7 from "../assets/images/events/fitness-2022/7.jpg";

import acs1 from "../assets/images/events/africa-centre-sbcci/1.jpg";
import acs2 from "../assets/images/events/africa-centre-sbcci/2.jpg";
import acs3 from "../assets/images/events/africa-centre-sbcci/3.jpg";
import acs4 from "../assets/images/events/africa-centre-sbcci/4.jpg";
import acs5 from "../assets/images/events/africa-centre-sbcci/5.jpg";

import efb1 from "../assets/images/events/edmonton-food-bank-Dec-2022/20220115_161913.jpg";
import efb2 from "../assets/images/events/edmonton-food-bank-Dec-2022/20220115_162439.jpg";
import efb3 from "../assets/images/events/edmonton-food-bank-Dec-2022/20220115_162502.jpg";
import efb4 from "../assets/images/events/edmonton-food-bank-Dec-2022/38963.JPEG";
import efb5 from "../assets/images/events/edmonton-food-bank-Dec-2022/38966.JPEG";
import efb6 from "../assets/images/events/edmonton-food-bank-Dec-2022/39048.JPEG";
import efb7 from "../assets/images/events/edmonton-food-bank-Dec-2022/39049.JPEG";
import efb8 from "../assets/images/events/edmonton-food-bank-Dec-2022/43979.JPEG";

import mm1 from "../assets/images/events/mommy-and-me/Mommy-&-Me.png";

import mdo1 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(1).jpg";
import mdo2 from "../assets/images/events/moms-day-out/BeautyPlus_20220730202156333_save.jpg";
import mdo3 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(2).jpg";
import mdo4 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(3).jpg";
import mdo5 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(4).jpg";
import mdo6 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(5).jpg";
import mdo7 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(6).jpg";
import mdo8 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17(7).jpg";
import mdo9 from "../assets/images/events/moms-day-out/PHOTO-2023-06-22-09-57-17.jpg";

export const homeContent = {
  showcaseHeader1: "stronger",
  showcaseHeader2: "together",
  showcaseHeader3: "about us",
  showcaseParagraph:
    " Mesh of Mothers Foundation was founded in 2021 during COVID 19 pandemic as an organization to bridge the gap and create a positive environment and services to meet the needs of African Canadians mothers and women in Edmonton and Alberta as a whole.",
  showcaseImg: strongerTogetherImg,
  motheringHeader: "mothering up!",
  motheringP1:
    "Motherhood is challenging \n in itself and feeling like you \n are alone makes it even harder.",
  motheringP2:
    "We are a healthy thriving community in which Afro Canadian mothers can gather, grow and learn while reaching their fullest potential in life and motherhood. Here's why you're safe with us!",
  motheringImg: motheringUpImg,
  empoweredHeader1: "Get",
  empoweredHeader2: "empowered",
  empoweredP1:
    "From workshops & seminars to support groups and networking events, our diverse programs are dedicated to equipping you!",
  empoweredP2:
    "The Mesh of Mothers Foundation offers a range of empowering programs designed to uplift and support African Canadian mothers and women in Edmonton and Alberta",
  empoweredImg: getEmpoweredImg,
  supportCauseHeader: "support a cause",
  supportCauseParagraph:
    "When you donate to Mesh of Mothers, you become a champion of diversity, equality, and social justice. You demonstrate your commitment to creating a more inclusive society—one where Afro-Heritage mothers are seen, heard, and celebrated. Your donation is not just a financial contribution, it is a generous gesture toward helping M.O.M to be “The leading voice of Afro-heritage maternal health.”",
  supportCauseImg: supportImg,

  mediaMemHeader: "resources",
  mediaMemParagraph: `The "M.O.M Resource Hub" is a comprehensive database specially designed to support Black mothers in Canada in their journey of motherhood. It comprises of BIOPOC professionals who understand and are willing to support. The M.O.M Resource Hub serves as a virtual haven, providing an extensive range of valuable resources and connections tailored to the unique needs and experiences of Black mothers. `,
  mediaMemImg: mediaMembershipImg,

  ceoSection: {
    title: "unveiling \n the vision",
    subTitle: "A word from our Founder",
    details: [
      "As the proud Founder of our Black Mothers Organization, I have a compelling vision burning in my heart. We are not just another organization; we are destined to be ",
      "THE LEADING VOICE FOR AFRO-HERITAGE MATERNAL WELL-BEING",
      ".\n\nTogether, we will bridge the gaps that have separated us for far too long. We will break barriers that have limited our potential, our dreams, and our opportunities. Our voices will be heard, amplified, and respected. Together, we will create a world where every black mother can flourish and be celebrated.",
    ],
    image: ceoImg,
  },

  buildingTogetherHeader: "building \n together",
  buildingTogetherHeader2: "our partners",
  buildingTogetherParagraph:
    "We extend our heartfelt gratitude to our esteemed partners for standing alongside us as we work tirelessly to create a brighter future for Afro heritage mothers and their families. Together, we are a force for positive transformation and empowerment",
  buildingTogetherImg: buildingTogetherImg,
  partnerLogos: [
    [
      { alt: "Fit Your Life", logo: fitYourLifeImg },
      { alt: "Africa Centre", logo: acImg },
    ],
    [
      { alt: "Mindfulness Services", logo: mindfulnessServicesImg },
      { alt: "United Way Certificate", logo: csfImg },
    ],
    [
      { alt: "Terra Centre", logo: TerraImg },

      { alt: "Edmonton Food Bank", logo: efbImg },
    ],
    [
      { alt: "Velte Technology Solutions", logo: VelteLogo },
      { alt: "Hello Courage", logo: hcImg },
    ],
    [
      { alt: "The Mustard Seed", logo: msImg },
      { alt: "It's Logical", logo: ilImg },
    ],
  ],
  joinSectionHeader: "join us!",
  joinSectionParagraph:
    "Are you looking for a community that understands you and the nuances of motherhood? If so, you have come to the right place Mama, join our community.",
  joinSectionImg: joinUsImg,

  successHeader1: "architects of success",
  successHeader2: "meet the board",
  members: [
    {
      id: 1,
      info: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate vel magni sunt, officia repellat culpa ipsum quasi libero blanditiis error rem consequatur omnis, earum reiciendis commodi nemo, sit esse. Omnis. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate vel magni sunt, officia repellat culpa ipsum quasi libero blanditiis error rem consequatur omnis, earum reiciendis commodi nemo, sit esse. Omnis.",
      image: "",
      class: "member",
    },
    {
      id: 2,
      info: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate vel magni sunt, officia repellat culpa ipsum quasi libero blanditiis error rem consequatur omnis, earum reiciendis commodi nemo, sit esse. Omnis. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate vel magni sunt, officia repellat culpa ipsum quasi libero blanditiis error rem consequatur omnis, earum reiciendis commodi nemo, sit esse. Omnis.",
      image: "",
      class: "member reversed",
    },
    {
      id: 3,
      info: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate vel magni sunt, officia repellat culpa ipsum quasi libero blanditiis error rem consequatur omnis, earum reiciendis commodi nemo, sit esse. Omnis. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate vel magni sunt, officia repellat culpa ipsum quasi libero blanditiis error rem consequatur omnis, earum reiciendis commodi nemo, sit esse. Omnis.",
      image: "",
      class: "member",
    },
  ],
};

export const aboutContent = {
  helloContent:
    "Mesh of Mothers Foundation was founded in 2021 during COVID-19 pandemic as an organization to bridge the gap and create a positive environment and services to meet the needs of African Canadian mothers and women in Edmonton and surrounding areas.",
  helloContent2: `When the Founder Millicent Asiedu became a mother, her outlook on life shifted. Millicent became cognizant of the invisible and visible load afro heritage mothers navigated daily in order to achieve optimal well-being. In addition, she witnessed the strength and resiliency of mothers.
  Motherhood is challenging and feeling like you are alone makes it even harder. Concurrently, being from a different ethnicity & background creates another layer of barrier while merging the Canadian culture. Minority populations, specifically, mothers of African backgrounds have been ignored/silenced for many years when it comes to narratives surrounding parenting/motherhood. Mesh of Mothers (M.O.M) hopes to create safe places for mothers to congregate, share knowledge and experiences about our challenges and strengths while making new connections. M.O.M is creating a healthy and thriving community. A community whereby Afro-Canadian mothers can connect, grow and learn while reaching their fullest potential in life and motherhood.`,
  ourLogo:
    "A representation of strong women who are creating a community is shown with the 3 dots on the top. The 3 letters are interlocked to show unity, alliance, and togetherness. The 3 colors are clear representations of uniqueness and inclusivity. Afro-women from all ethnicity, culture, and religion in one space, under one mission!! come one, come all!",
  mission:
    "To provide culturally relevant programs and services to meet the unique needs of afro heritage mothers",
  vision: "“The Leading Voice For Afro-Heritage Maternal Wellbeing.”",
  values: ["Belonging", "Inspiration", "Appreciation", "Compassion", "Pride"],
  missionImg: missionImg,
  visionImg: visionImg,
  valuesImg: valuesImg,
  architectsSection: {
    title: "Architects of Success",
    subtitle: "Meet the Board",
    members: [
      {
        name: "Millicent Asiedu",
        title: "Executive Director",
        image: member1,
        paragraph1:
          "Millicent is a Mental Health Therapist and a registered Social Worker with ACSW. She holds a Masters of Social Work degree from York University as well as a Bachelor's in Psychology from Trent University. She is employed with the government of Alberta; assisting Edmonton and surrounding communities while supporting individuals in addressing social challenges.",
        paragraph2: `As the Chief Executive Officer for Mesh of Mothers Foundation Inc. it is no surprise that Millicent is passionate about M.O.M (Mesh Of Mothers) as she’s also a mother of two beautiful children. Millicent's passion and strong advocacy for women and motherhood is quite infectious as you could immediately sense it from conversing with her. 
      
        Millicent understands the intricate intersectionality between race, gender, and motherhood and how that impacts their well-being. Millicent shares her journey through fertility and motherhood in hopes of inspiring other black women to embrace their present moments regardless of circumstances. Millicent is vehemently excited as she creates and assists by creating a safe space for black mothers to connect and support each other as they navigate through life. These are ways we can increase maternal mental health and overall well-being. “It takes a village to also raise a mother”
      
        When Millicent is not engaging and bringing awareness to social issues impacting black mothers, She enjoys spending time with her family. She is also a Sunday school teacher helping children fall in love with God. She also indulges in reading, traveling, music and dancing (though not the best dancer).`,
      },
      {
        name: "Lesley Asiedu",
        title: "Treasurer",
        image: member2,
        paragraph1:
          "Lesley uses her entrepreneurial and healthcare philanthropic background as the Board Treasurer at Mesh of Mothers Foundation.",
        paragraph2: `She holds a Bachelor of Applied Science in Public Health and has over 6 years of experience in running a profitable online business. Lesley is extremely passionate about helping Afro-heritage mothers succeed and reach their full potential. Her goal for Mesh of Mothers Foundation is to cultivate meaningful and lasting relationships with Afro-heritage mothers in Edmonton and all over Canada. In Lesley’s spare time, she teaches cooking and baking classes to participants in different settings. She is excited for what the future holds for M.O.M.`,
      },
      {
        name: "Agnes Agyare",
        title: "",
        image: member3,
        paragraph1:
          `Agnes holds a bachelor degree in accounting and works as a staff accountant in an accounting firm.
Agnes is a proud mother of two wonderful children. As a dedicated autism mom, her journey has been filled with challenges, triumphs, and an unwavering commitment to raising awareness and support for families like hers, particularly within the Black community.

`,
        paragraph2: `Agnes has learned to navigate the complexities of autism—embracing her son’s individuality while advocating for his needs. This experience transformed her understanding of parenting and fueled her passion for supporting other Black mothers who are on similar journeys.
        Agnes believes that every mother deserves a community that understands her struggles and celebrates her victories. Her hope for MOM is to have a chapter in every corner of Canada so as to support the mothers in every Black community.
In her down time she enjoys travelling, reading or  dancing around the house to her favorite music, with her children joyfully joining in.
She is happy to join MOM in its commitment to ensuring that all Black mothers feel seen, heard, and empowered on their unique paths.`,
      },
    ],
  },
};

export const donateContent = {
  supportText:
    "Mesh Of Mothers (M.O.M) is committed to providing a seamless and secure payment process to its clients. The following payment processes and procedures have been developed to ensure that all payments are processed accurately and efficiently.",
  paymentMethodList: [
    "Bank Account Transfer",
    "Cheque",
    "Email Money Transfer to donate@meshofmothers.ca",
    "Other payment methods, as agreed upon between M.O.M and the client.",
  ],
  methods: [
    {
      title: "Bank Account Transfer",
      description:
        "Clients who wish to make payment via bank account transfer should follow these steps:",
      icon: payMethodIcon1,
      steps: [
        "Contact M.O.M via email or phone to request the necessary bank account details.",
        "Once the client receives the bank account details, they should initiate the transfer through their bank or online banking system.",
        "The client should email a copy of the transfer confirmation to M.O.M at donate@meshofmothers.ca",
        "Upon receipt of the transfer confirmation, M.O.M will confirm the receipt of payment and issue an invoice to the client.",
      ],
    },
    {
      title: "Cheque",
      description:
        "Clients who wish to make payment via cheque should follow these steps:",
      icon: payMethodIcon2,
      steps: [
        'Make the cheque payable to "Mesh of Mothers Foundation Inc".',
        "Upon receipt of the cheque, M.O.M will confirm the receipt of payment and issue an invoice to the client.",
      ],
    },
    {
      title: "Email Money Transfer to",
      description:
        "Clients who wish to make payment via email money transfer should follow these steps:",
      icon: payMethodIcon3,
      steps: [
        "Log in to their online banking system and navigate to the email money transfer section.",
        "Add donate@meshofmothers.ca as a recipient and follow the prompts to complete the transfer.",
        "In the message section, include the client's name, and any additional details.",
        "Upon receipt of the transfer, M.O.M will confirm the receipt of payment and issue an invoice to the client.",
      ],
    },
    {
      title: "Other payment methods",
      description:
        "Clients who wish to make payment via other payment methods should contact M.O.M to agree on the payment process and procedure.",
      icon: payMethodIcon4,
      steps: [
        "M.O.M will issue an invoice to the client once payment has been received.",
        "If payment is not received within the agreed-upon timeframe, M.O.M will follow up with the client via email or phone to request payment.",
        "If payment is still not received after two follow-ups, M.O.M may seek legal action to recover the outstanding amount.",
      ],
    },
  ],
};

export const membershipContent = {
  heroText: "Together we can do more",
  familySection: {
    header: "we are \n family",
    subTitle: "Discover the perfect \n motherhood sanctuary",
    textContent:
      "Are you looking for a community that understands you and the nuances of motherhood? if so, You have come to the right place mama. We are truly happy to have you here. This group is for you, we are here to support each other in our journey through womanhood and motherhood because there is nothing like a sisterhood amid life. This is a brief introduction to something magical that we all have a chance to be a part of! This is a safe and positive space for us! To ask questions, get ideas from each other, and inspire and be inspired. I hope that this sisterhood will transcend beyond this as we MESH!!",
    image: famImg,
  },
  volunteerSection: {
    header: "volunteer",
    subTitle: "Extend a helping hand",
    textContent:
      "Want to learn how you can make a difference? consider volunteering with M.O.M. Our volunteers are diverse and come with the passion and zeal to support our vision. This is one of the most impactful ways you can help increase maternal well-being. Our volunteers have the opportunity to learn about the many ways they can work towards improving health outcomes for afro heritage mothers M.O.M. believes that the best way for our initiatives to be successful is for each member of the community to actively get involved. To learn more about getting involved, \n click the button below!",
    image: volunteerImg,
  },
};
const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);

export const event = [
  {
    id: "1",
    image: img1,
    title: "Psycho-education and awareness",
    details:
      "A rigorous set of sessions bringing awareness and providing psycho educational seminars and discussions to matters that are impacting our African mothers in the diaspora such as varying maternal health, cultural and language loss, domestic violence, mental health, financial freedom and wealth building, substance abuse, childcare, parenting in the western world and bridging the gap, isolation and guilt associated with parenting in the western world. ",
    location: "Edmonton",

    date: {
      day: "14",
      month: "september",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: img1 },
      // { img: img2 },
      // { img: img3 },
      // { img: img4 },
      // { img: img5 },
      // { img: img6 },
    ],
  },
  {
    id: "2",
    image: mdo1,
    title: "moms day out",
    details:
      "The importance of self-care is not just spoken about, our Mama’s gets to come out ALONE for special events to help foster the continuous message of self-care. We indulge in spoiling ourselves a little occasionally.",
    location: "Edmonton",

    date: {
      day: "19",
      month: "september",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: mdo1 },
      { img: mdo2 },
      { img: mdo3 },
      { img: mdo4 },
      { img: mdo5 },
      { img: mdo6 },
      { img: mdo7 },
      { img: mdo8 },
      { img: mdo9 },
    ],
  },
  {
    id: "3",
    image: mm1,
    title: "Mommy & Me programs / parenting programs ",
    details: `Mommy & Me/Parenting programs have a positive impact on the children of participating mothers. Through educational activities, workshops, and interactive sessions, these programs promote early childhood development, enhance parent-child bonding, and encourage positive parenting practices. This can lead to improved cognitive, emotional, and social outcomes for the children.
      
      Overall, Mommy & Me/Parenting programs within the M.O.M organization offer a supportive and enriching environment for black moms, addressing their social, psychological, and other needs while nurturing the well-being and development of their kids.`,
    location: "Edmonton",
    date: {
      day: "30",
      month: "december",
      year: "2021",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [{ img: mm1 }],
  },
  {
    id: "4",
    image: upcomingEventImg4,
    title: "fitness 2022",
    details:
      "The fitness program offered by Mesh Of Mothers (M.O.M) caters to Afro-heritage mothers, recognizing and celebrating their cultural identity. This program focuses on physical well-being and highlights the cultural impacts. By incorporating elements of Afro-centric dance, music, and traditions into fitness. This cultural infusion fosters a sense of pride, empowerment, and belonging among participants, promoting self-confidence and positive body image. Additionally, the program encourages community engagement and cultural exchange, strengthening bonds among Afro-heritage mothers and promoting a sense of unity within the Mesh Of Mothers organization.",
    location: "Edmonton",
    date: {
      day: "07",
      month: "july",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: ft1 },
      { img: ft2 },
      { img: ft3 },
      { img: ft4 },
      { img: ft5 },
      { img: ft6 },
      { img: ft7 },
    ],
  },
  {
    id: "5",
    image: ha4,
    title: "Harry ainley ",
    details:
      "The Harry Ainlay High School students came together to raise funds to support Mesh of Mothers Foundation during black history month. And as surprising and fulfilling as that is, Millicent, the Founder of Mesh Of Mothers made it a priority to pay them a visit to express her thanks in person!",
    location: "Edmonton",
    date: {
      day: "07",
      month: "july",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: ha1 },
      { img: ha2 },
      { img: ha3 },
      { img: ha4 },
      { img: ha5 },
      { img: ha6 },
    ],
  },
  {
    id: "6",
    image: mus6,
    title: "mustard seed ",
    details:
      "We extend our love beyond our walls, so why not organize a clothing drive to support other mothers, Families, and communities in need?",
    location: "Edmonton",
    date: {
      day: "05",
      month: "may",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: mus1 },
      { img: mus2 },
      { img: mus3 },
      { img: mus4 },
      { img: mus5 },
      { img: mus6 },
      { img: mus7 },
      { img: mus8 },
      { img: mus9 },
      { img: mus10 },
      { img: mus11 },
      { img: mus12 },
      { img: mus13 },
      { img: mus14 },
    ],
  },
  {
    id: "7",
    image: upcomingEventImg3,
    title: "M.O.M Meet",
    details:
      "A night of bliss, laughter, engagement, and a chance to educate ourselves of everything motherhood. The conversation was just as intriguing, the ambience added to everything that was needed to be achieved. A night where Afro Heritage mothers are together to break barriers and to do exploits.",
    location: "Edmonton",
    date: {
      day: "27",
      month: "august",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: meet2 },
      { img: meet3 },
      { img: meet4 },
      { img: meet5 },
      { img: meet6 },
      { img: meet7 },
      { img: meet8 },
      { img: meet9 },
      { img: meet10 },
      { img: meet11 },
      { img: meet12 },
      { img: meet13 },
      { img: meet14 },
      { img: meet15 },
      { img: meet16 },
      { img: meet17 },
      { img: meet18 },
      { img: meet19 },
      { img: meet20 },
      { img: meet21 },
    ],
  },
  {
    id: "8",
    image: acs1,
    title: "africa centre SBCCI ",
    details:
      "The Africa centre SBCCI program supporting Black organizations, Mesh Of Mothers was grateful to be part of the recipients of the SBCCI grant.",
    location: "Edmonton",
    date: {
      day: "08",
      month: "november",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: acs1 },
      { img: acs2 },
      { img: acs3 },
      { img: acs4 },
      { img: acs5 },
    ],
  },
  {
    id: "10",
    image: efb1,
    title: "Edmonton food bank ",
    details:
      "Edmonton food bank has been an imperative aspect of our community. There is always a need for more food and M.O.M being part of the community, we make sure to do our fair share of helping out. We look at it this way, M.O.M. supporting MOMS.",
    location: "Edmonton",
    date: {
      day: "07",
      month: "july",
      year: "2022",
    },

    youtubeURL: "eg2g6FPsdLI?list=PL4Gr5tOAPttLOY9IrWVjJlv4CtkYI5cI_",
    yt_title:
      "Sahara Desert & Dubai Desert | Drone footage | Free HD videos - no copyright",
    pictures: [
      { img: efb1 },
      { img: efb2 },
      { img: efb3 },
      { img: efb4 },
      { img: efb5 },
      { img: efb6 },
      { img: efb7 },
      { img: efb8 },
    ],
  },
];

export const events = shuffle(event);

export const upcomingEvents = [
  {
    id: "1",
    image: upcomingEventImg2,
    title: "black motherhood from our lens",
    date: "August 12, 2023",
    time: "4pm - 9pm",
    location: `Orchards Clubhouse
    4059 Orchards Dr SW
    Edmonton, AB T6X 1W5`,
    details:
      "Join us for a transformative journey as Black women come together to engage in heartfelt conversations, share personal narratives, and navigate the complexity of their experiences. This power-packed night will be a segment filled with stories of triumph, struggles, resilience, and joy intertwined. \n\n Through authentic dialogue and shared wisdom, we aim to edify, uplift, and encourage the generations to come of black motherhood. We will delve into the ups and downs, the turmoil and victories, and the unique challenges faced by Afro-Heritage mothers. Together, we will celebrate the strength, beauty, and grace inherent in their journey, while also addressing the systemic issues that impact their lives.  ",
  },
  {
    id: "2",
    image: upcomingEventImg1,
    title: "SPA & beauty night for moms",
    date: "July 27, 2023",
    time: "5pm - 7:30pm",
    location: `Terra West Multipurpose Room
    1032 146 Street NW, Edmonton, AB T5N3A2`,
    details: `Community engagement 

    Mesh of Mothers Foundation in partnership with Terra Centre welcomes teen moms for an evening of pampering..
    Think hair, nails, treats, and gifts! Dinner and childcare will be provided to guest.`,
  },
];

export const programsContent = {
  list: [
    {
      title: "Psycho-education and awareness",
      description:
        "The Psychoeducation and Awareness Program for Mesh Of Mothers (M.O.M) is a comprehensive initiative designed to provide mental health education, support, and empowerment for black mothers within the community. This program aims to address the unique experiences faced by Afro- heritage mothers while promoting their emotional well-being, resilience, and self-care.",
    },
    {
      title: "Maternal Mentorship",
      description:
        "The Maternal Mentorship Program for Mesh of Mothers (M.O.M) is a transformative initiative designed to provide guidance, support, and empowerment to black mothers within the community. This program aims to create a network of experienced mentors who can offer guidance, share knowledge, and serve as role models for black mothers navigating various aspects of motherhood.",
    },
    {
      title: "Resource finding",
      description:
        "The Resource Finding Program for Mesh Of Mothers (M.O.M) is a vital initiative designed to connect black mothers with essential resources, services, and support systems within the immediate community and Canada. This program aims to address the unique needs and challenges faced by black mothers by providing them with access to a wide range of resources that promote their well-being, empowerment, and overall success.",
    },
    {
      title: "Mommy & Me programs / parenting programs",
      description:
        "The Mommy & Me/Parenting Program for Mesh Of Mothers (M.O.M) is a valuable initiative designed to provide a supportive and enriching environment for black mothers and their children. This program focuses on nurturing the parent-child bond, enhancing parenting skills, and fostering holistic child development within the black community. ",
    },
    {
      title: "Community initiatives",
      description:
        "The Community Initiatives Program for Mesh Of Mothers (M.O.M) is a dynamic and inclusive initiative designed to engage and uplift the black mothers in the community. This program aims to foster a sense of belonging, provide opportunities for personal growth, and promote positive social change through various community-based initiatives.",
    },
  ],
};
