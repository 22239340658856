import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./rsvp.css";
import { upcomingEvents } from "../../constants/data";
import {  ScrollToTop } from "../../components";
import { Notification } from "../../components";

function convertToSlug(text) {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
}

const Rsvp = () => {
  const { upcomingEventSlug } = useParams();
  const [event, setEvent] = useState(null);
  const navigate = useNavigate();
  const [statusMessage, setStatusMessage] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("info");

  useEffect(() => {
    const selectedEvent = upcomingEvents.find(
      (event) => convertToSlug(event.title) === upcomingEventSlug
    );
    setEvent(selectedEvent);
  }, [upcomingEventSlug]);

  if (!event) {
    return <div>Loading...</div>;
  }

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    event_attending: "",
    number_of_people: "",
    allergies: "",
    comments: "",
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone_number: Yup.string().required("Phone number is required"),
    event_attending: Yup.string().required("Event is required"),
    number_of_people: Yup.number()
      .required("Number of people attending is required")
      .min(1, "Must be at least 1"),
    allergies: Yup.string(),
    comments: Yup.string().required(
      "Please write the names of those attending"
    ),
  });

  const sendEmail = (values, { resetForm }) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/send-rsvp`, values)
      .then((response) => {
        console.log(response.data);
        resetForm();
        // setStatusMessage("RSVP sent successfully!");
        handleNotification("RSVP sent successfully!", "success");
        navigate("/events");
      })
      .catch((error) => {
        console.error("Error sending RSVP:", error);
        // setStatusMessage("Failed to send RSVP. Please try again later.");
        handleNotification(
          "Failed to send RSVP. Please try again later.",
          "error"
        );
      });
  };

  const handleNotification = (message, type) => {
    setShowNotification(true);
    setNotificationMessage(message);
    setNotificationType(type);

    // hide the notification after  5 seconds
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  return (
    <>
      <section className="rsvp">
        <div className="container">
          <div className="header">
            <h1>RSVP for {event.title}</h1>
            <p>Please let us know if you will be able to make it.</p>
          </div>
          <div className="formContent">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={sendEmail}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="formGroup">
                    <div className="inputContainer">
                      <Field
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="inputContainer">
                      <Field
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="formGroup">
                    <div className="inputContainer">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email Address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>{" "}
                    <div className="inputContainer">
                      <Field
                        type="tel"
                        name="phone_number"
                        placeholder="Phone Number"
                      />
                      <ErrorMessage
                        name="phone_number"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="formGroup">
                    <div className="inputContainer">
                      <Field
                        type="number"
                        name="number_of_people"
                        placeholder="Number Of People Attending"
                      />
                      <ErrorMessage
                        name="number_of_people"
                        component="div"
                        className="error"
                      />
                    </div>{" "}
                    <div className="inputContainer">
                      <Field
                        type="text"
                        name="allergies"
                        placeholder="Please specify any food allergies and/or diet preferences."
                      />
                      <ErrorMessage
                        name="allergies"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="formSingle">
                    <Field
                      as="select"
                      name="event_attending"
                      id="event"
                      disabled
                      className="capitalize-text"
                    >
                      <option value={event.title}>{event.title}</option>
                    </Field>
                    <ErrorMessage
                      name="event_attending"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="description">
                    <Field
                      as="textarea"
                      name="comments"
                      id="comments"
                      cols="30"
                      rows="10"
                      placeholder="Write the names of those attending"
                    />
                    <ErrorMessage
                      name="comments"
                      component="div"
                      className="error"
                    />
                  </div>

                  <button
                    type="submit"
                    className="btnPrimary"
                    // style={{ width: "100%" }}
                    disabled={isSubmitting}
                  >
                    Send
                  </button>
                  {/* <p className="alertMsg">{statusMessage}</p> */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
     
      <ScrollToTop />
      {showNotification && (
        <Notification
          message={notificationMessage}
          notificationType={notificationType}
          onClose={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default Rsvp;
