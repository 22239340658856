import React, { useEffect, useState } from "react";
import "./contact-us.css";
import {  ScrollToTop } from "../../components";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagramSquare,
  FaYoutube,
  FaTiktok,
  FaPaperPlane,
} from "react-icons/fa";
import ContactUsSkeleton from "../../components/skeletons/ContactUsSkeleton";

const ContactUs = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <ContactUsSkeleton />;
  }
  return (
    <>
      <section className="contactTop pt-6">
        <div className="row text-center justify-content-center pb-4 mt-2">
          <div className="col-12">
            <h1>Contact us</h1>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-12 pr-lg-0">
              <div className="d-flex align-items-center mb-4">
                {/* social icons */}
                <div className="d-flex flex-column socialContainer">
                  <a href="https://www.facebook.com/" className="mt-2 mb-3">
                    <FaTwitter size={30} color="#0f7794" />
                  </a>
                  <a href="https://www.facebook.com/" className="mt-2 mb-3">
                    <FaInstagramSquare size={30} color="#0f7794" />
                  </a>
                  <a href="https://www.facebook.com/" className="mt-2 mb-3">
                    <FaFacebookF size={30} color="#0f7794" />
                  </a>
                  <a href="https://www.facebook.com/" className="mt-2 mb-3">
                    <FaYoutube size={30} color="#0f7794" />
                  </a>
                  <a href="https://www.facebook.com/" className="mt-2 mb-2">
                    <FaTiktok size={30} color="#0f7794" />
                  </a>
                </div>

                {/* get in touch form */}
                <div className="contactFormContainer">
                  <form>
                    <div className="">
                      <h2>Get in touch</h2>
                    </div>

                    <div className="py-4">
                      {/* full name */}
                      <div className="row">
                        <div className="col-12">
                          <div className="input-field">
                            <input
                              type="text"
                              id="fullName"
                              name="fullName"
                              required="required"
                              placeholder="Your Full name"
                            />
                          </div>
                        </div>
                      </div>

                      {/* email */}
                      <div className="row">
                        <div className="col-12">
                          <div className="input-field">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              required="required"
                              placeholder="Your email"
                            />
                          </div>
                        </div>
                      </div>

                      {/* phone number */}
                      <div className="row">
                        <div className="col-12">
                          <div className="input-field">
                            <input
                              type="text"
                              id="phoneNumber"
                              name="phoneNumber"
                              required="required"
                              placeholder="Phone number"
                            />
                          </div>
                        </div>
                      </div>

                      {/* message */}
                      <div className="row">
                        <div className="col-12">
                          <div className="input-field">
                            <input
                              type="text"
                              id="message"
                              name="message"
                              required="required"
                              placeholder="Send message"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <button className="submitBtn d-flex align-items-center justify-content-center">
                        <FaPaperPlane size={15} className="mr-2" />
                        <span className="mr-1">Submit</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-12 pl-lg-0">
              <div className="row">
                <div className="col-12 pr-3">
                  <h2 className="text-right mb-2 mr-3">Google maps</h2>
                </div>
              </div>

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d303582.86066459614!2d-113.49273724999999!3d53.526680549999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a0224580deff23%3A0x411fa00c4af6155d!2sEdmonton%2C%20AB%2C%20Canada!5e0!3m2!1sen!2scm!4v1686906755827!5m2!1sen!2scm"
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>

              <div className="row">
                <div className="col-12 pr-3">
                  <div className="text-right mb-2 mr-3">
                    <p className="mb-0">Edmonton, Alberta</p>
                    <p className="my-0">support@meshofmothers.ca</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <ScrollToTop />
    </>
  );
};

export default ContactUs;
