import React from "react";
import ScrollToTop from "../../components/ScrollToTop";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="policy pt-6">
        <div className="container pt-4">
          <div className="policyTextContent">
            <div className="policyContentHeader">
              <h1>Privacy Policy</h1>
              <small>Last Updated On 29-Dec-2022</small>{" "}
              <small>Effective Date 28-Dec-2022</small>
            </div>
            <div className="policyContent">
              <article>
                <p>
                  This Privacy Policy describes the policies of Mesh of Mothers
                  Foundation on the collection, use and disclosure of your
                  information that we collect when you use our website (
                  http://www.meshofmothers.ca ). (the “Service”). By accessing
                  or using the Service, you are consenting to the collection,
                  use and disclosure of your information in accordance with this
                  Privacy Policy. If you do not consent to the same, please do
                  not access or use the Service. We may modify this Privacy
                  Policy at any time without any prior notice to you and will
                  post the revised Privacy Policy on the Service. The revised
                  Policy will be effective 180 days from when the revised Policy
                  is posted in the Service and your continued access or use of
                  the Service after such time will constitute your acceptance of
                  the revised Privacy Policy. We, therefore recommend that you
                  periodically review this page.
                </p>
                <h4>Information We Collect:</h4>
                <p>
                  We will collect and process the following personal information
                  about you:
                  <li>Name</li>
                  <li>Email</li>
                  <li>Mobile</li>
                  <li>Date Of Birth</li>
                  <li>Address</li>
                  <li>Payment Info</li>
                  <li>Marital Status</li>
                  <li>Number of Children</li>
                </p>
                <h4>How We Use Your Information:</h4>
                <p>
                  We will use the information that we collect about you for the
                  following purposes:
                  <li>Creating user account</li>
                  <li>Testimonials</li>
                  <li>Processing payment</li>
                  <li>Support</li>
                  <li>Administration info</li>
                </p>
                <p>
                  If we want to use your information for any other purpose, we
                  will ask you for consent and will use your information only on
                  receiving your consent and then, only for the purpose(s) for
                  which grant consent unless we are required to do otherwise by
                  law.
                </p>
                <h4> Retention Of Your Information:</h4>
                <p>
                  We will retain your personal information with us for 90 days
                  to 2 years after user accounts remain idle or for as long as
                  we need it to fulfill the purposes for which it was collected
                  as detailed in this Privacy Policy. We may need to retain
                  certain information for longer periods such as record-keeping
                  / reporting in accordance with applicable law or for other
                  legitimate reasons like enforcement of legal rights, fraud
                  prevention, etc. Residual anonymous information and aggregate
                  information, neither of which identifies you (directly or
                  indirectly), may be stored indefinitely.
                </p>
                <h4>Your Rights:</h4>
                <p>
                  Depending on the law that applies, you may have a right to
                  access and rectify or erase your personal data or receive a
                  copy of your personal data, restrict or object to the active
                  processing of your data, ask us to share (port) your personal
                  information to another entity, withdraw any consent you
                  provided to us to process your data, a right to lodge a
                  complaint with statutory authority and such other rights as
                  may be relevant under applicable laws. To exercise these
                  rights, you can write to us at Info@meshofmothers.ca. We will
                  respond to your request in accordance with applicable law.
                  <br />
                  You may opt out of direct marketing communications or the
                  profiling we carry out for marketing purposes by writing to us
                  at Info@meshofmothers.ca.
                  <br />
                  Do note that if you do not allow us to collect or process the
                  required personal information or withdraw the consent to
                  process the same for the required purposes, you may not be
                  able to access or use the services for which your information
                  was sought.
                </p>
                <h4>Cookies Etc.</h4>{" "}
                <p>
                  To learn more about how we use these and your choices in
                  relation to these tracking technologies, please refer to our
                  Cookie Policy.
                </p>
                <h4>Security:</h4>
                <p>
                  The security of your information is important to us and we
                  will use reasonable security measures to prevent the loss,
                  misuse or unauthorized alteration of your information under
                  our control. However, given the inherent risks, we cannot
                  guarantee absolute security and consequently, we cannot ensure
                  or warrant the security of any information you transmit to us
                  and you do so at your own risk.
                </p>
                <h4>Grievance / Data Protection Officer:</h4>
                <p>
                  If you have any queries or concerns about the processing of
                  your information that is available with us, you may email our
                  Grievance Officer at{" "}
                  <Link
                    onClick={(e) =>
                      (window.location.href = "info@meshfmothers.ca")
                    }
                  >
                    Info@meshofmothers.ca
                  </Link>{" "}
                  . We will address your concerns in accordance with applicable
                  law.
                </p>
                {/* <p>Privacy Policy generated with CookieYes.</p> */}
              </article>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default PrivacyPolicy;
