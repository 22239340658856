import React, { useEffect, useState } from "react";
import "../../styles.css";
import "./programs_.css";
import { programsContent } from "../../constants/data";
import {  ScrollToTop } from "../../components";
import ProgramsSkeleton from "../../components/skeletons/ProgramsSkeleton";
import ProgramsMetadata from "../../components/generateMetadata/ProgramsMetadata";
import { Link } from "react-router-dom";

const Programs = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <ProgramsSkeleton />;
  }
  return (
    <>
      <ProgramsMetadata />
      {/* title */}
      <section className="titleSection pt-6 pb-5">
        <div className="container pt-3">
          <div className="row">
            <div className="col-12">
              <h1 className="supportText text-center">Programs</h1>
            </div>
          </div>
        </div>
      </section>

      {/* program list */}
      <section className="programList">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ol type="1">
                {programsContent.list.map((program, index) => (
                  <li key={index}>
                    <h3>
                      <Link
                        to={
                          program.title.toLowerCase() === "resource finding"
                            ? "/resources"
                            : "/events"
                        }
                        className="program-link"
                      >
                        {program.title}
                      </Link>
                    </h3>
                    <p>{program.description}</p>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default Programs;
